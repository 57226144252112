import React from "react";
import axios from "axios";
import { config } from "../../data";
import ListUser from "../../ListUser";
import ListPermission from "../../ListPermissions";
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [{ username: "", permission: "" }],
      permissions: [{ id: 0, name: "admin" }],
      dataLoaded: false,
      hasSavedChanges: false
    };
    this.getUsers();
    this.savedChangesTimeout = setTimeout(() => {});
  }
  getUsers = () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("__r_ksLT"));
    axios
      .post(config.APIurl + "getUsers.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => res.data)
      .then(data => {
        if (data.status === "success") {
          this.setState({
            users: data.users,
            permissions: data.permissions,
            dataLoaded: true
          });
        } else {
          console.log(data);
        }
      })
      .catch(err => () => {
        this.setState({ dataLoaded: true });
        console.log(err);
      });
  };
  savedChanges = () => {
    this.setState({
      hasSavedChanges: true
    });
    this.savedChangesTimeout = setTimeout(() => {
      this.setState({ hasSavedChanges: false });
    }, 3000);
  };
  render() {
    let userArray = [<ListUser madeUser={this.getUsers} className="header" />];
    let permissionArray = [<ListPermission className="header" />];

    this.state.users.forEach((user, index) => {
      userArray.push(
        <ListUser
          permissions={this.state.permissions}
          user={user}
          key={index}
          madeUser={this.getUsers}
          savedChanges={this.savedChanges}
        ></ListUser>
      );
    });
    userArray.push(
      <ListUser
        className="footer"
        madeUser={this.getUsers}
        permissions={this.state.permissions}
      />
    );
    this.state.permissions.forEach((permission, index) => {
      permissionArray.push(
        <ListPermission permission={permission} madeUser={this.getUsers} />
      );
    });
    permissionArray.push(
      <ListPermission className="footer" madeUser={this.getUsers} />
    );
    return (
      <div className="Users adminpage">
        <div className="blueBox brukere">
          <h3>Brukere</h3>
          {this.state.hasSavedChanges && (
            <div className="userEntry success">
              Rettighetsgruppe ble oppdatert
            </div>
          )}
          {this.state.dataLoaded && userArray}
          {!this.state.dataLoaded && <p>Laster inn...</p>}
        </div>
        <div className="yellowBox rettighetsgrupper">
          <h3>Rettighetsgrupper</h3>
          {this.state.dataLoaded && permissionArray}
          {!this.state.dataLoaded && <p>Laster inn...</p>}
        </div>
      </div>
    );
  }
}

export default Users;
