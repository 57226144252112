import React from "react";
class ContactBanners extends React.Component {
  render() {
    let data = this.props.data;
    let nummer = data.loaded
      ? data.find(item => item.name === "nummer").content
      : "...";
    let email = data.loaded
      ? data.find(item => item.name === "email").content
      : "...";
    let facebook = data.loaded
      ? data.find(item => item.name === "facebook").content
      : "...";
    return (
      <div className="contactBanners">
        <h3>
          <i className="fas fa-phone-square-alt" />
          <a href={"tel:" + data.nummer}>{nummer}</a>
        </h3>
        <h3>
          <i className="fas fa-envelope-square" />
          <a href={"maitl:" + data.email}>{email}</a>
        </h3>
        <h3>
          <i className="fab fa-facebook-square" />
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            facebook
          </a>
        </h3>
        <h3>
          <i className="fas fa-arrow-alt-circle-up" />
          <a href="." onClick={this.props.backToTop} role="button">
            tilbake til toppen
          </a>
        </h3>
      </div>
    );
  }
}

export default ContactBanners;
