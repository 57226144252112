import React from "react";
import Axios from "axios";
import { config } from "./data";
export default class ListFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editOpen: false,
      checkedList: props.docPermissions,
      uPError: false,
      uPErrorMessage: "",
      isUP: false
    };
    this.timer = setTimeout(() => {});
  }
  toggleEdit = () => {
    this.setState({ editOpen: !this.state.editOpen });
  };
  getIconFromExtension = ext => {
    let imageArray = ["png", "jpg", "jpeg", "gif", "bmp", "gif", "pdf", "tiff"];
    let docArray = ["doc", "docx", "docm", "dot"];
    if (imageArray.includes(ext)) {
      return <i className="fas fa-file-image" />;
    } else if (docArray.includes(ext)) {
      return <i className="far fa-file-word"></i>;
    } else {
      return <i className="fas fa-file-alt" />;
    }
  };
  check = e => {
    let checkedList = [...this.state.checkedList];
    if (e.target.checked) {
      !checkedList.includes(e.target.name) && checkedList.push(e.target.name);
    } else {
      let index = checkedList.indexOf(e.target.name);
      index > -1 && checkedList.splice(index, 1);
    }
    this.setState({ checkedList });
  };
  deleteFile = () => {
    if (this.state.hasClickedDel) {
      clearTimeout(this.timer);
      // Delete
      const formData = new FormData();
      formData.append("fileid", this.props.fileid);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      this.setState({ isUP: true, uPError: false });
      Axios.post(config.APIurl + "removeFile.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          console.log(data);
          if (data.status === "success") {
            this.setState({ editOpen: false, uPError: false, isUP: false });
            this.props.update();
          } else {
            this.setState({
              isUP: false,
              uPError: true,
              uPErrorMessage: data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isUP: false,
            uPError: true,
            uPErrorMessage: "Ukjent feil..."
          });
        });
    } else {
      this.setState({ hasClickedDel: true });
      this.timer = setTimeout(() => {
        this.setState({ hasClickedDel: false });
      }, 3000);
    }
  };
  update = () => {
    //
    const newPermissions = JSON.stringify(this.state.checkedList);
    const formData = new FormData();
    formData.append("permissions", newPermissions);
    formData.append("fileid", this.props.fileid);
    formData.append("token", localStorage.getItem("__r_ksLT"));
    this.setState({ isUP: true, uPError: false });
    Axios.post(config.APIurl + "changeFilePermission.php", formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
      .then(res => res.data)
      .then(data => {
        if (data.status === "success") {
          this.setState({ editOpen: false, uPError: false, isUP: false });
          this.props.update();
        } else {
          this.setState({
            isUP: false,
            uPError: true,
            uPErrorMessage: data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isUP: false,
          uPError: true,
          uPErrorMessage: "Ukjent feil..."
        });
      });
  };
  render() {
    let permCheck = [];
    this.props.permissions.forEach((permission, index) => {
      permission.name !== "admin" &&
        permCheck.push(
          <li key={"permCheck" + index}>
            <label>
              <input
                type="checkbox"
                name={permission.name}
                onChange={this.check}
                defaultChecked={this.props.docPermissions.includes(
                  permission.name
                )}
              />{" "}
              {permission.name}
            </label>
          </li>
        );
    });
    return this.state.editOpen ? (
      <React.Fragment>
        {this.state.uPError && (
          <div classNAme="fileEntry error">{this.state.uPErrorMessage}</div>
        )}
        <div className="fileEntry" style={{ padding: "20px 10px" }}>
          {this.getIconFromExtension(this.props.extension)}{" "}
          <a href={this.props.url} target="_blank" rel="noopener noreferrer">
            {this.props.name}
          </a>
          <button onClick={this.update}>Oppdater fil</button>{" "}
          <button className="danger" onClick={this.deleteFile}>
            {this.state.hasClickedDel ? "Sikker?" : "Slett fil"}
          </button>
          <br />
          <h4>Oppdater rettigheter</h4>
          <ul>{permCheck}</ul>
        </div>
      </React.Fragment>
    ) : (
      <div className="fileEntry">
        {this.getIconFromExtension(this.props.extension)}{" "}
        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
          {this.props.name}
        </a>
        <button onClick={this.toggleEdit}>Rediger eller slett fil</button>
      </div>
    );
  }
}
