import React from "react";
import axios from "axios";
import { config } from "./data";
class ContactForm extends React.Component {
  state = {
    navn: "",
    firma: "",
    emne: "",
    epost: "",
    melding: "",
    error: false,
    errorMessage: "",
    isSendingMail: false,
    hasSentMail: false,
    showError: false
  };
  handleChange = evt => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value
    });
  };
  submit = e => {
    e.preventDefault();
    const formData = new FormData();
    let errors = false;
    if (this.state.navn === "") {
      errors = true;
    }
    if (this.state.melding === "") {
      errors = true;
    }
    if (this.state.epost === "") {
      errors = true;
    }
    if (!errors) {
      formData.append("navn", this.state.navn);
      formData.append("firma", this.state.firma);
      formData.append("emne", this.state.emne);
      formData.append("melding", this.state.melding);
      formData.append("epost", this.state.epost);
      this.setState({ hasSentMail: false, isSendingMail: true });
      axios
        .post(config.APIurl + "sendMail.php", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(res => res.data)
        .then(data => {
          if (data.status !== "error") {
            this.setState({
              hasSentMail: true,
              isSendingMail: false,
              error: false,
              showError: false
            });
          } else {
            this.setState({
              isSendingMail: false,
              error: true,
              errorMessage: data.message,
              showError: false
            });
          }
        })
        .catch(err =>
          this.setState({
            isSendingMail: false,
            error: true,
            errorMessage: "Ukjent feil oppstod, vennligst prøv igjen senere",
            showError: false
          })
        );
    } else {
      ///...
      this.setState({ showError: true });
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showError ? (
          <span className="errorSpan">
            Vennligst fyll ut navn, epostadresse og melding.
          </span>
        ) : (
          ""
        )}
        <form
          className="contactForm"
          onSubmit={this.isSendingMail ? this.doNothing : this.submit}
        >
          <table>
            <tbody>
              {this.state.error && (
                <tr>
                  <td className="errorTD" valign="top">
                    Feil:
                  </td>
                  <td className="errorTD">{this.state.errorMessage}</td>
                </tr>
              )}
              {this.state.hasSentMail && (
                <tr>
                  <td className="successTD" valign="top"></td>
                  <td className="successTD">Meldingen ble sendt!</td>
                </tr>
              )}
              <tr>
                <td valign="top">
                  <label htmlFor="navn">Ditt navn *</label>
                </td>
                <td valign="top">
                  <input
                    type="text"
                    name="navn"
                    onChange={this.handleChange}
                    value={this.state.navn}
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <label htmlFor="navn">Epostadresse *</label>
                </td>
                <td valign="top">
                  <input
                    type="text"
                    name="epost"
                    onChange={this.handleChange}
                    value={this.state.epost}
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <label htmlFor="firma">Navn på firma</label>
                </td>
                <td valign="top">
                  <input
                    type="text"
                    name="firma"
                    onChange={this.handleChange}
                    value={this.state.firma}
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <label htmlFor="emne">Emne</label>
                </td>
                <td valign="top">
                  <input
                    type="text"
                    name="emne"
                    onChange={this.handleChange}
                    value={this.state.emne}
                  />
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <label htmlFor="melding">Melding *</label>
                </td>
                <td valign="top">
                  <textarea
                    name="melding"
                    onChange={this.handleChange}
                    value={this.state.melding}
                  />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <button
                    className="block"
                    onClick={this.isSendingMail ? this.doNothing : this.submit}
                  >
                    {this.isSendingMail ? "Sender melding..." : "Send melding"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </React.Fragment>
    );
  }
}

export default ContactForm;
