import React from "react";
class DoubleSection extends React.Component {
  render() {
    return this.props.contained === true ? (
      <div className="doubleSection">{this.props.children}</div>
    ) : (
      <div className="doubleSection page-center">{this.props.children}</div>
    );
  }
}

export default DoubleSection;
