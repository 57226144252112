import React from "react";
class Header extends React.Component {
  goHome = () => {
    window.location.replace(".");
  };
  render() {
    let data = this.props.data;
    let subtitle = data.loaded
      ? data.find(item => item.name === "subTitle").content
      : "...";
    return (
      <header className="App-header">
        <div className="page-center">
          <h1>Ryfylke Kranservice as</h1>
          <img
            src="https://haakon.underbakke.net/ryfylkekranservice.png"
            alt="logo"
            className="logo"
            onClick={this.goHome}
          />
          <p className="headerSubtitle">{subtitle}</p>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#151515"
            fillOpacity="1"
            d="M0,64L80,58.7C160,53,320,43,480,58.7C640,75,800,117,960,112C1120,107,1280,53,1360,26.7L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          />
        </svg>
      </header>
    );
  }
}

export default Header;
