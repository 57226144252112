import React from "react";
class ButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: this.props.buttons
    };
  }
  click = event => {
    this.props.handleSort(event.target.getAttribute("value"));
  };
  render() {
    return (
      <div className="buttonGroup">
        {this.state.buttons.map((item, index) => (
          <button value={item.value} onClick={this.click} key={index}>
            {item.title}
          </button>
        ))}
      </div>
    );
  }
}

export default ButtonGroup;
