import React from "react";
import DoubleSection from "./DoubleSection";
import Section from "./Section";
import ContactForm from "./ContactForm";
import ContactBanners from "./ContactBanners";
class Footer extends React.Component {
  goToAdmin = () => {
    window.location.href = "/_admin";
  };
  goHome = () => {
    window.location.href = ".";
  };
  goToGuest = () => {
    window.location.href = "/deling";
  };
  render() {
    let data = this.props.data;
    let footerTitle = data.loaded
      ? data.find(item => item.name === "footerTitle").content
      : "...";
    let footerSubtitle = data.loaded
      ? data.find(item => item.name === "footerSubtitle").content
      : "...";
    return (
      <footer id="kontakt" name="kontakt">
        <div className="page-center">
          {!this.props.onAdmin && (
            <React.Fragment>
              <DoubleSection>
                <Section>
                  <h2>{footerTitle}</h2>
                  <p>{footerSubtitle}</p>
                  <ContactForm data={this.props.data} />
                </Section>
                <ContactBanners
                  data={this.props.data}
                  backToTop={this.props.backToTop}
                />
              </DoubleSection>
              <img
                src="https://haakon.underbakke.net/ryfylkekranservicebw.png"
                alt="Logo svart/hvit"
              />
            </React.Fragment>
          )}

          <p className="credit">
            Nettside utviklet av{" "}
            <a href="https://haakon.underbakke.net">Håkon Underbakke</a>
            <br />
            <br />
            <button onClick={this.props.onAdmin ? this.goHome : this.goToAdmin}>
              {this.props.onAdmin ? "Forside" : "Administrasjon"}
            </button>
            <br />
            {!this.props.onGuest && (
              <button onClick={this.goToGuest}>Gjestelogin</button>
            )}
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
