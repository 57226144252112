import React from "react";
import LargeQuote from "./LargeQuote";
import Section from "./Section";
import FacebookSection from "./FacebookSection";
class Intro extends React.Component {
  render() {
    let data = this.props
      .data; /* 
    let sectionText = data.loaded
      ? data.find(item => item.name === "sectionText").content
      : "...";
    let sectionTitle = data.loaded
      ? data.find(item => item.name === "sectionTitle").content
      : "..."; */
    return (
      <div className="pushIn">
        <LargeQuote data={data} executeScroll={this.props.executeScroll} />
        <Section>
          <FacebookSection contained={true} data={data} />
        </Section>
      </div>
    );
  }
}

export default Intro;
