import React from "react";
class LargeQuote extends React.Component {
  render() {
    let data = this.props.data;
    let largeQuote = data.loaded
      ? data.find(item => item.name === "largeQuote").content
      : "...";
    let contactButton = data.loaded
      ? data.find(item => item.name === "contactButton").content
      : "...";
    return (
      <div className="largeQuote page-center">
        {largeQuote}
        <button onClick={this.props.executeScroll} className="contactButton">
          <i className="fas fa-comment" /> {contactButton}
        </button>
      </div>
    );
  }
}

export default LargeQuote;
