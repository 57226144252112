import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Intro from "../Intro";
import Gallery from "../Gallery";
import "../../App.scss";
const scrollToRef = ref => {
  console.log(ref.current);
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "smooth"
  }); // General scroll to element function
};

class IndexPage extends React.Component {
  state = {
    loggedIn: false,
    data: this.props.data
  };
  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
    this.headerRef = React.createRef();
  }
  goToFooter = () => scrollToRef(this.footerRef);
  backToTop = e => {
    e.preventDefault(); // because trigger is a link, not a button
    scrollToRef(this.headerRef);
  };
  render() {
    return (
      <div className="App">
        <div ref={this.headerRef}>
          <Header data={this.props.data} />
        </div>
        <Intro data={this.props.data} executeScroll={this.goToFooter} />
        <Gallery data={this.props.data} />
        <div ref={this.footerRef}>
          <Footer backToTop={this.backToTop} data={this.props.data} />
        </div>
      </div>
    );
  }
}

export default IndexPage;
