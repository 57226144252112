import React from "react";
class Gallery extends React.Component {
  openImage = e => {
    window.open(e.target.getAttribute("src"), "_blank");
  };
  render() {
    let data = this.props.data;
    let images = [];
    if (data.loaded !== false) {
      JSON.parse(data.find(item => item.name === "gallery").content).forEach(
        (img, index) =>
          images.push(
            <div className="img" key={index}>
              <img
                src={img.url}
                loading="lazy"
                alt={"Gallery " + index}
                key={index}
                onClick={this.openImage}
              />
            </div>
          )
      );
    }
    return (
      <div className="pushIn">
        <div className="page-center gallery">{images}</div>
        <div className="clear" />
      </div>
    );
  }
}

export default Gallery;
