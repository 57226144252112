import React from "react";
import {
  EditorState,
  RichUtils,
  // eslint-disable-next-line
  convertFromHTML,
  // eslint-disable-next-line
  ContentState
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import addLinkPlugin from "./plugins/addLink";
import { stateToHTML } from "draft-js-export-html";
class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.plugins = [addLinkPlugin];
    let editorState;
    if (props.content) {
      /* const blocksFromHTML = convertFromHTML(props.content);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML);
      editorState = EditorState.createWithContent(contentState); */
      editorState = EditorState.createEmpty();
    } else {
      editorState = EditorState.createEmpty();
    }
    this.state = {
      editorState: editorState
    };
  }
  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };
  onChange = editorState => {
    this.setState({ editorState });
    this.props.output !== null &&
      this.props.output(
        stateToHTML(this.state.editorState.getCurrentContent())
      );
  };
  _onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
  };
  _addLink = () => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Vennligst oppgi nettaddressen til linken");
    if (!link) {
      this.onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    console.log(content);
    console.log(contentWithEntity);
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  _onItalicClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC")
    );
  };
  _onUnderlineClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
    );
  };
  _addHeading = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, "H2"));
  };

  render() {
    return (
      <div className="Editor">
        <div className="Editor-buttongroup">
          <button onClick={this._addLink}>
            <i className="fas fa-link"></i>
          </button>
          <button onClick={this._onBoldClick}>
            <i className="fas fa-bold"></i>
          </button>
          <button onClick={this._onItalicClick}>
            <i className="fas fa-italic"></i>
          </button>
        </div>
        <Editor
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          plugins={this.plugins}
          defaultEditorState={this.state.editorState}
        />
      </div>
    );
  }
}
export default CustomEditor;
