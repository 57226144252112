import React from "react";
class Documentation extends React.Component {
  render() {
    return (
      <div className="Documentation adminpage">
        <div className="blueBox">
          <h3>Brukersystem</h3>
          <p>Det fins 2 typer forskjellige brukere.</p>
          <ol>
            <li>Administratorer</li>
            <li>Gjester</li>
          </ol>
          <h4>Administrator-rettigheter</h4>
          <p>
            Administratorer har tilgang til admin-systemet, som vil si at de
            kan...
          </p>
          <ol>
            <li>Redigere bildegalleri</li>
            <li>Redigere side-tekster</li>
            <li>
              Redigere, slette eller legge til brukere og rettighetsgrupper
              <br />
              <i>
                ("admin"-bruker [bruker med "admin" som brukernavn] kan ikke
                slettes, men administratorer kan redigere passord)
              </i>
            </li>
            <li>Laste opp, slette eller redigere filer</li>
          </ol>
          <p>
            Reserver brukere med admin-rettigheter til ansatte personer du
            stoler på og kan holde ansvarlig.
          </p>
          <h4>Gjeste-rettigheter</h4>
          <p>
            Gjester er brukere som har hvilken som helst aen rettighetsgruppe en
            "admin". Gjester kan se filer som har samme rettighetsgruppe som
            brukeren.
          </p>
          <p>
            Gjester logger inn for å se filer på{" "}
            <a
              href="https://ryfylkekranservice.no/deling"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#6495ca" }}
            >
              ryfylkekranservice.no/deling
            </a>
          </p>
        </div>
        <div className="yellowBox">
          <h3>Redigering av nettsted</h3>
          <p>
            <ol>
              <li>
                For å redigere tekster, facebook-link, nummer eller liknendes,
                gå til <strong>"Rediger tekster"</strong>.
              </li>
              <li>
                For å redigere det offentlige bilde-galleriet, gå til{" "}
                <strong>"Rediger bilde-galleri"</strong>. Gjør endringene dine,
                også trykk på <strong>"Lagre"</strong> <br />
                For å legge til et bilde, trykk på{" "}
                <strong>"Legg til bilde"</strong> og last opp bildet.
                <br />
                For å endre plassering på bildet, dra det over et annet bilde
                for å bytte plass.
                <br />
                For å fjerne et bilde, trykk <strong>"Fjern bilde"</strong> på
                bildet du vil fjerne.
                <br />
                <strong>OBS:</strong> Endringene på bilde-galleriet blir ikke
                lagret før du trykker på <strong>Lagre</strong>. For å resette
                til lagret versjon, trykk <strong>Kanseller</strong>
              </li>
            </ol>
          </p>
        </div>
      </div>
    );
  }
}

export default Documentation;
