import React from "react";
import Axios from "axios";
import { config } from "./data";
class ListPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
      isUP: false,
      uPError: false,
      uPErrorMessage: "",
      newPermissionName: ""
    };
    this.focusRef = React.createRef();
  }
  toggleAdd = () => {
    this.setState(
      {
        showAdd: !this.state.showAdd
      },
      () => {
        this.state.showAdd && this.focusRef.current.select();
      }
    );
  };
  onChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  submitPerm = () => {
    let formData = new FormData();
    formData.append("name", this.state.newPermissionName);
    formData.append("token", localStorage.getItem("__r_ksLT"));
    this.setState({ isUP: true, uPError: false });
    Axios.post(config.APIurl + "addPermission.php", formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
      .then(res => res.data)
      .then(data => {
        if (data.status === "success") {
          this.setState({
            showAdd: false,
            uPError: false,
            newPermissionName: "",
            isUP: false
          });
          this.props.madeUser();
        } else {
          this.setState({
            isUP: false,
            uPError: true,
            uPErrorMessage: data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ uPError: true, uPErrorMessage: "Ukjent feil..." });
      });
  };
  removePerm = () => {
    let formData = new FormData();
    formData.append("name", this.props.permission.name);
    formData.append("token", localStorage.getItem("__r_ksLT"));
    this.setState({ isUP: true, uPError: false });
    Axios.post(config.APIurl + "removePermission.php", formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
      .then(res => res.data)
      .then(data => {
        console.log(data);
        if (data.status === "success") {
          this.setState({
            showAdd: false,
            uPError: false,
            newPermissionName: ""
          });
          this.props.madeUser();
        } else {
          this.setState({ uPError: true, uPErrorMessage: data.message });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ uPError: true, uPErrorMessage: "Ukjent feil..." });
      });
  };
  doNothing = () => console.log("nothing");
  render() {
    const permission = this.props.permission || "";
    const name = permission.name || "";
    const className = this.props.className || "";
    if (className === "header") {
      return (
        <div className="userEntry header" key="userHeader">
          Rettighetsnavn <b></b> <span>Slett</span>
        </div>
      );
    } else if (className === "footer") {
      if (!this.state.showAdd) {
        return (
          <div className="userEntry footer" key="addUser">
            <button onClick={this.toggleAdd}>
              + Legg til rettighetsgruppe
            </button>
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <div className="userEntry footer inputs" key="addPermInput">
              <input
                type="text"
                name="newPermissionName"
                value={this.state.newPermissionName}
                ref={this.focusRef}
                defaultValue="Rettighetsgruppe navn"
                onChange={this.onChange}
              />
            </div>
            <div className="userEntry footer" key="addPermButton">
              <button
                onClick={this.state.isUP ? this.doNothing : this.submitPerm}
              >
                {this.state.isUP ? "Vent litt" : "Opprett rettighetsgruppe"}
              </button>
              <button
                onClick={this.toggleAdd}
                style={{ backgroundColor: "#333", borderColor: "#333" }}
              >
                Avbryt
              </button>
            </div>
          </React.Fragment>
        );
      }
    } else if (name !== "admin") {
      return (
        <div className="userEntry" {...this.props}>
          {name}
          <b></b>
          <span>
            {name !== "Offentlig" && (
              <button onClick={this.removePerm}>Slett</button>
            )}
            {name === "Offentlig" && <div className="error">Obligatorisk</div>}
          </span>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default ListPermissions;
