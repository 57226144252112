import React from "react";
class Section extends React.Component {
  render() {
    return this.props.contained === true ? (
      <div
        className={
          this.props.pushIn
            ? "section pushInner " + this.props.size
            : "section " + this.props.size
        }
      >
        {this.props.children}
      </div>
    ) : (
      <div
        className={
          this.props.pushIn
            ? "section page-center pushInner " + this.props.size
            : "section page-center " + this.props.size
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export default Section;
