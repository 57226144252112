import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IndexPage from "./components/pages/IndexPage";
import AdminPage from "./components/pages/AdminPage";
import GuestLogin from "./components/pages/GuestLogin";
import { config } from "./components/data";
import axios from "axios";
import "./App.scss";
/*
  Gjenstår:
    Gjestebruker login-side & API
    Gjestebruker fil-liste
*/

class App extends React.Component {
  state = { data: { loaded: false } };
  getPublicData = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(config.APIurl + "getPublicData.php", {}, config.axios)
        .then(data => data.data)
        .then(data => resolve(data.data))
        .catch(err => console.log(err));
    });
  };
  componentDidMount() {
    this.getPublicData()
      .then(data => {
        data.loaded = true;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    return (
      <div className="App">
        <Router onUpdate={this.scrollToTop}>
          <Switch>
            <Route
              exact
              path="/_admin"
              render={props => <AdminPage data={this.state.data} />}
              data={this.data}
            />
            <Route
              exact
              path="/deling"
              render={props => <GuestLogin data={this.state.data} />}
              data={this.data}
            />
            <Route
              path="/"
              render={props => <IndexPage data={this.state.data} />}
              data={this.data}
            />
            <Route
              render={props => <AdminPage data={this.state.data} />}
              data={this.data}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
