import React from "react";
import axios from "axios";
import Header from "../Header";
import Section from "../Section";
import Footer from "../Footer";
import LoginForm from "../LoginForm";
import ButtonGroup from "../ButtonGroup";
import Upload from "./_admin/Upload";
import Gallery from "./_admin/Gallery";
import Pages from "./_admin/Pages";
import Users from "./_admin/Users";
import Documentation from "./_admin/Documentation";
import { config } from "../data";
import "../../App.scss";
/*
GJENSTÅR:
  * Responsiv design
*/
class AdminPage extends React.Component {
  state = {
    loggedIn: false,
    token: null,
    currentPage: "pages",
    data: this.props.data,
    username: "",
    passoword: "",
    error: false,
    errorResponse: { message: "" }
  };
  buttons = [
    { title: "Bilde-galleri", value: "gallery" },
    { title: "Rediger tekster", value: "pages" },
    { title: "Bruker-administrering", value: "users" },
    { title: "Filsystem", value: "files" },
    { title: "Dokumentasjon", value: "docs" }
  ];
  componentDidMount() {
    if (localStorage.getItem("__r_ksLT") !== null) {
      this.checkLogin({
        method: "token",
        token: localStorage.getItem("__r_ksLT")
      });
    }
  }
  logout = () => {
    localStorage.removeItem("__r_ksLT");
    window.location.reload();
  };
  checkLogin = params => {
    const { method, token } = params;
    let data = {};
    if (method === "password" || method === null) {
      data.username = this.state.username;
      data.password = this.state.password;
      data.method = "password";
    } else if (method === "token") {
      data.token = token;
      data.method = "token";
    }
    axios
      .post(config.APIurl + "login.php", data, config.axios)
      .then(data => data.data)
      .then(data => {
        data.status === "error"
          ? this.setState({ error: true, errorResponse: data })
          : this.setState(
              {
                error: false,
                errorResponse: null,
                loggedIn: true,
                token: data.token
              },
              () => {
                localStorage.setItem("__r_ksLT", data.token);
              }
            );
      })
      .catch(err => console.log);
  };
  updateState = async (state, callback = () => {}) => {
    this.setState(state, callback);
  };
  changePage = page => {
    this.setState({
      currentPage: page
    });
  };
  render() {
    let data = this.state.data;
    return (
      <div className="App">
        <Header data={this.props.data} />
        {this.state.loggedIn === false ? (
          <Section
            contained={false}
            pushIn={true}
            size="small"
            className="adminSectionWrapper"
          >
            <h2>Vennligst logg inn</h2>
            {this.state.error && (
              <div className="error">{this.state.errorResponse.message}</div>
            )}
            <LoginForm
              checkLogin={this.checkLogin}
              updateState={this.updateState}
            />
          </Section>
        ) : (
          <div key="adminSectionWrapper" className="adminSectionWrapper">
            <Section contained={false} pushIn={true}>
              <h2>Administrering</h2>
              <ButtonGroup
                buttons={this.buttons}
                handleSort={this.changePage}
              />
              <SwitchPages
                page={this.state.currentPage}
                data={this.props.data}
                token={this.state.token}
              />
            </Section>
            <Section contained={false} pushIn={true}>
              <button
                onClick={this.logout}
                style={{ background: "#333", borderColor: "#000" }}
              >
                Logg ut
              </button>
            </Section>
          </div>
        )}
        <Footer data={data} onAdmin={true}></Footer>
      </div>
    );
  }
}
class SwitchPages extends React.Component {
  render() {
    switch (this.props.page) {
      case "files":
        return <Upload data={this.props.data} />;
      case "gallery":
        return <Gallery data={this.props.data} />;
      case "pages":
        return <Pages data={this.props.data} token={this.props.token} />;
      case "users":
        return <Users />;
      case "docs":
        return <Documentation />;
      default:
        return (
          <div className="adminpage">
            Hvis du trenger hjelp med redigering, vennligst kontakt{" "}
            <a href="mailto:hu@ligl.no">hu@ligl.no</a>
          </div>
        );
    }
  }
}
export default AdminPage;
