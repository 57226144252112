import React from "react";
import "../App.scss";

class LoginForm extends React.Component {
  state = {
    username: "",
    password: ""
  };
  login = () => {
    this.props.checkLogin({ method: "password" });
  };
  handleChange = evt => {
    const value = evt.target.value;
    this.setState(
      {
        ...this.state,
        [evt.target.name]: value
      },
      () => {
        this.props.updateState({
          username: this.state.username,
          password: this.state.password
        });
      }
    );
  };
  render() {
    return (
      <table className="loginForm">
        <tbody>
          <tr>
            <td>Brukernavn</td>
            <td>
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </td>
          </tr>
          <tr>
            <td>Passord</td>
            <td>
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <button className="block" onClick={this.login}>
                Logg inn
              </button>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <a href="/">
                <i className="fas fa-arrow-left" /> Avbryt
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default LoginForm;
