import React from "react";
import Axios from "axios";
import { config } from "./data";
class ListUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addUser: false,
      changePass: false,
      madeUser: false,
      isMakingUser: false,
      newUserError: false,
      nUEM: "",
      newPassword: ""
    };
    this.focusRef = React.createRef();
    this.passRef = React.createRef();
    this.deleteTimer = setTimeout(() => {}, 5000);
  }
  onChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  toggleAddUser = () => {
    this.setState({ addUser: !this.state.addUser }, () => {
      if (this.state.addUser) {
        this.focusRef.current.select();
      }
    });
  };
  toggleChangePass = () => {
    this.setState({ changePass: !this.state.changePass }, () => {
      if (this.state.changePass) {
        //
        this.passRef.current.focus();
      }
    });
  };
  submitNewPassowrd = () => {
    if (this.state.newPassword === "" || this.state.newPassword === undefined) {
      this.setState({
        uPError: true,
        uPErrorMessage: "Vennligst oppgi passord"
      });
    } else {
      let formData = new FormData();
      formData.append("password", this.state.newPassword);
      formData.append("username", this.props.user.username);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      this.setState({ isUP: true, uPError: false });
      Axios.post(config.APIurl + "changePassword.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.status === "success") {
            this.setState({ changePass: false, uPError: false });
            this.props.madeUser();
          } else {
            this.setState({ uPError: true, uPErrorMessage: data.message });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ uPError: true, uPErrorMessage: "Ukjent feil..." });
        });
    }
  };
  submitUser = () => {
    if (this.state.username === undefined || this.state.username === "") {
      // No username
      this.setState({
        newUserError: true,
        nUEM: "Vennligst oppgi brukernavn",
        isMakingUser: false
      });
    } else if (
      this.state.password === undefined ||
      this.state.password === ""
    ) {
      // No password
      this.setState({
        newUserError: true,
        nUEM: "Vennligst oppgi passord",
        isMakingUser: false
      });
    } else {
      // Submit
      let formData = new FormData();
      formData.append("username", this.state.username);
      formData.append("password", this.state.password);
      let permission =
        this.state.newUserPermission === undefined
          ? "Offentlig"
          : this.state.newUserPermission;
      formData.append("permission", permission);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      this.setState({ isMakingUser: true });
      Axios.post(config.APIurl + "addUser.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.status === "success") {
            this.setState({ madeUser: true, isMakingUser: false });
            this.props.madeUser();
          } else {
            this.setState({
              newUserError: true,
              nUEM: data.message,
              isMakingUser: false
            });
            console.log(data);
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            newUserError: true,
            nUEM: "Ukjent feil",
            isMakingUser: false
          });
        });
    }
  };
  submitUser = () => {
    if (this.state.username === undefined || this.state.username === "") {
      // No username
    } else if (
      this.state.password === undefined ||
      this.state.password === ""
    ) {
      // No password
    } else {
      // Submit
      let formData = new FormData();
      formData.append("username", this.state.username);
      formData.append("password", this.state.password);
      let permission =
        this.state.newUserPermission === undefined
          ? "Offentlig"
          : this.state.newUserPermission;
      formData.append("permission", permission);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      this.setState({ isMakingUser: true });
      Axios.post(config.APIurl + "addUser.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.status === "success") {
            this.setState({
              madeUser: true,
              isMakingUser: false,
              changePass: false
            });
            this.props.madeUser();
          } else {
            this.setState({
              newUserError: true,
              nUEM: data.message,
              isMakingUser: false
            });
            console.log(data);
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            newUserError: true,
            nUEM: "Ukjent feil",
            isMakingUser: false
          });
        });
    }
  };
  deleteUser = () => {
    if (this.state.deleteProgress) {
      clearTimeout(this.deleteTimer);
      this.setState({ deletingUser: true });
      let formData = new FormData();
      formData.append("username", this.props.user.username);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      Axios.post(config.APIurl + "deleteUser.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.status === "success") {
            this.props.madeUser();
            this.setState({ deletingUser: false });
          } else {
            console.log(data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({ deleteProgress: true });
      this.deleteTimer = setTimeout(() => {
        this.setState({ deleteProgress: false });
      }, 3000);
    }
  };
  changePermission = () => {
    this.setState({ changingPermission: true });
    let formData = new FormData();
    formData.append("username", this.props.user.username);
    formData.append("permission", this.state.changedPermission);
    formData.append("token", localStorage.getItem("__r_ksLT"));
    Axios.post(config.APIurl + "changePermission.php", formData, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
      .then(res => res.data)
      .then(data => {
        if (data.status === "success") {
          this.props.madeUser();
          this.props.savedChanges();
          this.setState({ changingPermission: false });
        } else {
          console.log(data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  hasChangedPermission = e => {
    this.setState({ changedPermission: e.target.value }, () => {
      this.changePermission();
    });
  };
  doNothing = () => console.log("nothing");
  render() {
    const user = this.props.user || "";
    const username = user.username || "";
    const userPermission = user.permission || "";
    const permissions = this.props.permissions || [{ name: "" }];
    const className = this.props.className || "";
    if (!this.state.changePass) {
      if (className === "header") {
        return (
          <div className="userEntry header" key="userHeader">
            Brukernavn <b>Passord</b> <span>Rettighetsgruppe</span>
          </div>
        );
      } else if (className === "footer") {
        let permissionArray = [];
        permissions.forEach((permission, index) => {
          if (permission.name !== "admin") {
            permissionArray.push(
              <option value={permission.name} key={"newuserPermission" + index}>
                {permission.name}
              </option>
            );
          }
        });
        return this.state.addUser ? (
          <React.Fragment>
            {this.state.newUserError && (
              <div className="userEntry error">{this.state.nUEM}</div>
            )}
            {this.state.madeUser && (
              <div className="userEntry success">Bruker ble opprettet</div>
            )}
            <div className="userEntry footer inputs" key="addUserInput">
              <input
                type="text"
                name="username"
                ref={this.focusRef}
                defaultValue="Brukernavn"
                onChange={this.onChange}
              />
              <input
                type="password"
                name="password"
                defaultValue="Passord"
                onChange={this.onChange}
              />
              <select
                value={this.state.newUserPermission}
                name="newUserPermission"
                onChange={this.onChange}
              >
                {permissionArray}
              </select>
            </div>
            <div className="userEntry footer" key="addUserButton">
              <button onClick={this.submitUser}>Opprett bruker</button>
              <button
                onClick={this.toggleAddUser}
                style={{ backgroundColor: "#333", borderColor: "#333" }}
              >
                Avbryt
              </button>
            </div>
          </React.Fragment>
        ) : (
          <div className="userEntry footer" key="addUser">
            <button onClick={this.toggleAddUser}>+ Legg til bruker</button>
          </div>
        );
      } else {
        let permissionArray = [];
        permissions.forEach((permission, index) => {
          if (permission.name !== "admin") {
            permissionArray.push(
              <option value={permission.name} key={"b" + index}>
                {permission.name}
              </option>
            );
          } else {
            permissionArray.push(
              <option value={permission.name} key={"a" + index} disabled={true}>
                {permission.name}
              </option>
            );
          }
        });
        return (
          <div className="userEntry">
            {username}
            <b>
              <button onClick={this.toggleChangePass}>
                Endre passord / Slett
              </button>
            </b>
            <span>
              <select
                value={userPermission}
                disabled={userPermission === "admin"}
                onChange={this.hasChangedPermission}
              >
                {permissionArray}
              </select>
            </span>
          </div>
        );
      }
    } else {
      //
      return (
        <React.Fragment>
          <div className="userEntry highlighted header">
            Nytt passord for "{username}"
          </div>
          {this.state.uPError && (
            <div className="userEntry error">{this.state.uPErrorMessage}</div>
          )}
          <div className="userEntry highlighted">
            <input
              type="password"
              name="newPassword"
              ref={this.passRef}
              onChange={this.onChange}
            />
            <span>
              <button
                onClick={
                  this.state.isMakingUser
                    ? this.doNothing
                    : this.submitNewPassowrd
                }
              >
                {this.state.isMakingUser ? "Vent litt..." : "Oppdater passord"}
              </button>
            </span>
            {username !== "admin" && (
              <span>
                <button className="danger" onClick={this.deleteUser}>
                  {this.state.deleteProgress ? "Er du sikker?" : "Slett bruker"}
                </button>
              </span>
            )}
          </div>
          <div className="userEntry highlighted">
            <b>
              <button
                onClick={this.toggleChangePass}
                style={{ backgroundColor: "#333", borderColor: "#333" }}
              >
                Avbryt
              </button>
            </b>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default ListUser;
