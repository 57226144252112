import React from "react";
import axios from "axios";
import Editor from "./Editor";
import { config } from "../../data";
class Pages extends React.Component {
  state = {
    activePage: null,
    texts: [
      { name: "Header", data: [1, 2] },
      { name: "Intro-seksjon", data: [3, 4] },
      // { name: "Tekst-seksjon", data: [5, 12] },
      { name: "Footer", data: [6, 7, 8, 10, 11] }
    ],
    dataHasLoaded: false,
    isSaving: false,
    error: false
  };
  changeActivePage = e => {
    this.setState({ activePage: e.target.getAttribute("data-id") });
  };
  handleOutput = output => {
    this.setState({ html: output });
  };
  makeTitleReadable = title => {
    switch (title) {
      case "title":
      case "sectionTitle":
      case "footerTitle":
        return "Tittel";
      case "subTitle":
      case "footerSubtitle":
        return "Under-tittel";
      case "largeQuote":
        return "Tekst";
      case "sectionText":
        return "Tekst";
      case "contactButton":
        return "Kontakt-knapp tekst";
      case "nummer":
        return "Telefon-nummer";
      case "email":
        return "Kontakt e-post addresse";
      case "facebook":
        return "Facebook-link";
      default:
        return title;
    }
  };
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  dataLoaded = () => {
    // Not the best way of doing things but it should only call once so whatever
    this.setState(
      {
        dataHasLoaded: true
      },
      () => {
        this.props.data.forEach(item => {
          this.setState({
            [item.name]: item.content
          });
        });
      }
    );
  };
  save = () => {
    let data = this.state;
    data.token = this.props.token;
    console.log(new URLSearchParams(data).toString());
    this.setState({ isSaving: true, hasSaved: false });
    axios
      .post(config.APIurl + "update.php", this.state, config.axios)
      .then(data => data.data)
      .then(data => {
        data.status === "error"
          ? this.setState({
              error: true,
              response: data,
              isSaving: false,
              hasSaved: true
            })
          : this.setState({
              error: false,
              response: data,
              isSaving: false,
              hasSaved: true
            });
      })
      .catch(err => console.log(err));
  };
  doNothing = () => console.log("nothing");
  render() {
    let groupsOfTexts = [];
    groupsOfTexts.push(
      <div key="saveBtn1" className="right">
        {this.state.hasSaved && (
          <div style={{ "text-align": "center" }}>Endringer ble lagret!</div>
        )}
        <button
          className="save-btn"
          onClick={this.state.isSaving ? this.doNothing : this.save}
        >
          <i className="far fa-save"></i>{" "}
          {this.state.isSaving ? "Vent litt..." : "Lagre"}
        </button>
      </div>
    );
    if (this.props.data.loaded) {
      !this.state.dataHasLoaded && this.dataLoaded();
      this.state.texts.forEach((item, index) => {
        // Loop through categories
        let texts = this.props.data.filter(text =>
          item.data.includes(parseInt(text.id))
        ); // All of the texts under the category
        let jsxTexts = []; //
        texts.forEach((text, index) => {
          //
          jsxTexts.push(
            <tr key={"tr" + index}>
              <td valign="top" key={"td1" + index}>
                {this.makeTitleReadable(text.name)}
              </td>
              <td valign="top" key={"td2" + index}>
                {text.type === "html" && (
                  <Editor
                    output={this.handleOutput}
                    content={text.content || " "}
                    key={"htmltext" + index}
                  />
                )}
                {text.type === "text" && (
                  <input
                    type="text"
                    defaultValue={text.content}
                    name={text.name}
                    key={"inputtext" + index}
                    onChange={this.handleChange}
                  />
                )}
              </td>
            </tr>
          );
        });
        groupsOfTexts.push(
          <div key={"div" + index}>
            <h4>{item.name}</h4>
            <table>
              <tbody>{jsxTexts}</tbody>
            </table>
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        <div className="Pages adminpage">
          <h3>Rediger tekster</h3>
          {groupsOfTexts}
          <div className="right">
            <button
              className="save-btn"
              onClick={this.state.isSaving ? this.doNothing : this.save}
            >
              {this.state.isSaving ? "Vent litt..." : "Lagre"}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Pages;
