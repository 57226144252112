import React from "react";
class FacebookSection extends React.Component {
  render() {
    let className =
      this.props.contained === true
        ? "facebookSection"
        : "facebookSection page-center";
    return (
      <div className={className}>
        <div
          className="fb-page"
          data-href="https://www.facebook.com/ryfylkekranservice/"
          data-tabs="timeline"
          data-width="300"
          data-small-header="true"
          data-adapt-container-width="false"
          data-hide-cover="true"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/ryfylkekranservice/"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/ryfylkekranservice/">
              Ryfylke Kranservice As
            </a>
          </blockquote>
        </div>
      </div>
    );
  }
}

export default FacebookSection;
