import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Section from "../Section";
import LoginForm from "../LoginForm";
import axios from "axios";
import { config } from "../data";
export default class GuestLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      loggedIn: false,
      files: [{ url: "https://ryfylkekranservice.no/uploads/laster" }]
    };
  }
  getIconFromExtension = ext => {
    let imageArray = ["png", "jpg", "jpeg", "gif", "bmp", "gif", "pdf", "tiff"];
    let docArray = ["doc", "docx", "docm", "dot"];
    if (imageArray.includes(ext)) {
      return <i className="fas fa-file-image" />;
    } else if (docArray.includes(ext)) {
      return <i className="far fa-file-word"></i>;
    } else {
      return <i className="fas fa-file-alt" />;
    }
  };
  checkLogin = params => {
    const { method, token } = params;
    let data = {};
    if (method === "password" || method === null) {
      data.username = this.state.username;
      data.password = this.state.password;
      data.method = "password";
    } else if (method === "token") {
      data.token = token;
      data.method = "token";
    }
    axios
      .post(config.APIurl + "guestLogin.php", data, config.axios)
      .then(data => data.data)
      .then(data => {
        data.status === "error"
          ? this.setState({ error: true, errorResponse: data })
          : this.setState(
              {
                error: false,
                errorResponse: null,
                loggedIn: true,
                token: data.token
              },
              () => {
                this.loadFiles();
              }
            );
      })
      .catch(err => console.log);
  };
  loadFiles = () => {
    axios
      .post(
        config.APIurl + "getFiles.php",
        { token: this.state.token },
        config.axios
      )
      .then(data => data.data)
      .then(data => {
        data.status === "error"
          ? this.setState({ error: true, errorResponse: data })
          : this.setState({
              files: [...data.documents]
            });
      })
      .catch(err => console.log);
  };
  updateState = async (state, callback = () => {}) => {
    this.setState(state, callback);
  };
  render() {
    let files = [];
    this.state.files.forEach((doc, index) => {
      let fileName = doc.url.split("uploads/");
      fileName = fileName[1];
      files.push(
        <div className="fileEntry" key={index}>
          {this.getIconFromExtension(doc.extension)}{" "}
          <a href={doc.url} target="_blank" rel="noopener noreferrer">
            {fileName}
          </a>
        </div>
      );
    });
    return (
      <div className="App adminSectionWrapper">
        <Header data={this.props.data} />
        {!this.state.loggedIn ? (
          <Section contained={false} pushIn={true} size="small">
            <h2>Gjestelogin</h2>
            {this.state.error && (
              <div className="error">{this.state.errorResponse.message}</div>
            )}
            <LoginForm
              checkLogin={this.checkLogin}
              updateState={this.updateState}
            />
          </Section>
        ) : (
          <div>
            <Section contained={false} pushIn={true} size="small">
              <h2>Dine filer</h2>
              {files}
            </Section>
          </div>
        )}
        <Footer data={this.props.data} onGuest={true} onAdmin={true} />
      </div>
    );
  }
}
