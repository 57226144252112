import React from "react";
import Axios from "axios";
import { config } from "../../data";
var placeholder = document.createElement("li");
placeholder.className = "placeholder";
const sortObjects = object => {
  return object.sort((a, b) => {
    if (a.priority < b.priority) return -1;
    if (a.priority < b.priority) return 1;
    return 0;
  });
};
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSorted: false,
      showUpload: false,
      label: "",
      file: null,
      isUploading: false,
      uploadError: false,
      isSaving: false,
      saveError: false,
      hasSaved: false
    };
    if (
      this.props.data[8] !== null &&
      this.props.data[8] !== undefined &&
      this.props.data[8] !== ""
    ) {
      this.state.images = JSON.parse(this.props.data[7].content) || [];
      this.state.savedImages = JSON.parse(this.props.data[7].content) || [];
    } else {
      this.state.images = [];
      this.state.savedImages = [];
    }
  }

  onDrag = event => {
    event.preventDefault();
    this.setState({
      draggedItem: event.target.getAttribute("data-priority")
    });
  };
  onDragOver = event => {
    event.preventDefault();
    event.target.classList.add("draggedOver");
  };
  onDragLeave = event => {
    event.preventDefault();
    event.target.classList.remove("draggedOver");
  };
  onDrop = event => {
    event.preventDefault();
    let droppedOnto = parseInt(event.target.getAttribute("data-priority"));
    let droppedItem = parseInt(this.state.draggedItem);
    let newImages = [];
    this.state.images.forEach(img => {
      let url = img.url;
      let priority = img.priority;
      if (priority === droppedItem) {
        priority = droppedOnto;
      } else if (priority === droppedOnto) {
        priority = droppedItem;
      }
      newImages.push({ url, priority });
    });
    this.setState({
      images: sortObjects(newImages)
    });
    event.target.classList.remove("draggedOver");
  };
  reset = () => {
    this.setState({ images: this.state.savedImages, showUpload: false });
  };
  removeImage = event => {
    let imageID = event.target.getAttribute("data-priority");
    let newImages = [];
    this.state.images.forEach(img => {
      let url = img.url;
      let priority = img.priority;
      console.log(imageID);
      if (parseInt(priority) !== parseInt(imageID)) {
        newImages.push({ url, priority });
      }
    });
    this.setState({
      images: sortObjects(newImages)
    });
  };
  showUpload = () => {
    this.setState({ showUpload: !this.state.showUpload });
  };
  addImage = imageURL => {
    let newImages = [];
    let highestPriority = 0;
    this.state.images.forEach(img => {
      let url = img.url;
      let priority = img.priority;
      highestPriority = priority > highestPriority ? priority : highestPriority;
      newImages.push({ url, priority });
    });
    newImages.push({ url: imageURL, priority: highestPriority + 1 });
    this.setState({ images: sortObjects(newImages) });
  };
  doNothing = e => e.preventDefault();
  doUpload = e => {
    e.preventDefault();
    let file = this.state.file;
    if (file !== null) {
      this.setState({ isUploading: true, uploadError: false });
      const formData = new FormData();
      formData.append("avatar", file);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      Axios.post(config.APIurl + "upload.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
        .then(res => res.data)
        .then(data => {
          if (data.error === false) {
            let fileURL = data.url;
            console.log(fileURL);
            fileURL = fileURL.split("uploads/");
            fileURL = "https://ryfylkekranservice.no/api/uploads/" + fileURL[1];
            this.addImage(fileURL);
            this.setState({ isUploading: false, showUpload: false }, () => {
              console.log(JSON.stringify(this.state.images));
            });
          } else {
            this.setState({ isUploading: false, uploadError: true });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ isUploading: false, uploadError: true });
        });
    }
  };
  fileChanged = e => {
    switch (e.target.name) {
      // Updated this
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          this.setState({ label: e.target.files[0].name });
        }
        break;
      default:
        this.setState({ label: e.target.value.replace("C:\\fakepath\\", "") });
    }
    this.setState({ file: e.target.files[0] });
  };
  save = () => {
    let images = JSON.stringify(this.state.images);
    this.setState({ isSaving: true, saveError: false, hasSaved: false });
    Axios.post(
      config.APIurl + "updateGallery.php",
      { images, token: localStorage.getItem("__r_ksLT") },
      config.axios
    )
      .then(res => res.data)
      .then(data => {
        console.log(data);
        if (data.status !== "error") {
          this.setState({ isSaving: false, hasSaved: true });
        } else {
          this.setState({ isSaving: false, saveError: true });
        }
      })
      .catch(err => this.setState({ isSaving: false, saveError: true }));
  };
  render() {
    let data = this.props.data;
    let gallery = data.loaded ? this.state.images : null;
    let images = [];
    if (gallery !== 0) {
      sortObjects(gallery).forEach((img, index) =>
        images.push(
          <li
            className="li"
            draggable={true}
            onDrag={event => this.onDrag(event)}
            onDrop={event => this.onDrop(event)}
            onDragOver={event => this.onDragOver(event)}
            onDragLeave={event => this.onDragLeave(event)}
            data-priority={img.priority}
            key={index}
          >
            <img src={img.url} alt={"Bilde #" + index} key={index} />
            <div />
            <div className="button-cont">
              <button
                className="dark"
                onClick={this.removeImage}
                data-priority={img.priority}
              >
                Fjern bilde
              </button>
            </div>
          </li>
        )
      );
    }
    return (
      <div className="Gallery adminpage">
        <h3>Rediger bilde-galleri</h3>
        {this.state.saveError && (
          <div>Det oppstod en feil med lagring, vennligst prøv igjen...</div>
        )}
        {this.state.hasSaved && <div>Galleri ble oppdatert</div>}
        <div className="buttonGroup">
          <button onClick={this.showUpload}>
            <i className="fas fa-plus-circle"></i>{" "}
            {this.state.showUpload ? "Legg til bilde" : "Legg til bilde"}
          </button>
          {this.state.savedImages === this.state.images ? (
            <div />
          ) : (
            <div className="rightGroup">
              <React.Fragment>
                <button
                  className="success"
                  onClick={this.state.isSaving ? this.doNothing : this.save}
                >
                  <i className="far fa-save"></i>{" "}
                  {this.state.isSaving ? "Lagrer..." : "Lagre"}
                </button>
                <button className="dark" onClick={this.reset}>
                  <i className="fas fa-ban"></i> Kanseller
                </button>
              </React.Fragment>
            </div>
          )}
        </div>
        {this.state.showUpload && (
          <form
            encType="multipart/form-data"
            onSubmit={this.state.isUploading ? this.doNothing : this.doUpload}
          >
            {this.state.uploadError && (
              <div>Det oppstod en feil, vennligst prøv igjen.</div>
            )}
            <div className="galleryFileUpload">
              <input
                type="file"
                accept="image/*"
                onChange={this.fileChanged}
                name="file"
                id="file"
              />
              <label htmlFor="file">
                {this.state.label === "" ? "Velg bilde" : this.state.label}
              </label>
              <button
                onClick={
                  this.state.isUploading ? this.doNothing : this.doUpload
                }
              >
                {this.state.isUploading ? "Laster opp..." : "Last opp"}
              </button>
            </div>
          </form>
        )}
        <div className="galleryContainer">
          <ul>{images}</ul>
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

export default Gallery;
