import React from "react";
import axios from "axios";
import ListFile from "../../ListFile";
import { config } from "../../data";
class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "Velg fil",
      permissions: [{ id: 0, name: "Laster inn..." }],
      documents: [{ url: "", extension: "", permissions: "[]" }],
      checkedList: [],
      fileError: false,
      file: null,
      isUploading: false,
      hasUploaded: false,
      noFileError: false,
      search: "",
      selectSearch: "Vis alle filer"
    };
    this.getUsers();
    this.getFiles();
  }
  fileChanged = e => {
    switch (e.target.name) {
      // Updated this
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          this.setState({ label: e.target.files[0].name });
        }
        break;
      default:
        this.setState({ label: e.target.value.replace("C:\\fakepath\\", "") });
    }
    this.setState({ file: e.target.files[0] });
  };
  search = e => {
    const value = e.target.value.toUpperCase();
    this.setState({ realSearch: value, search: e.target.value });
  };
  getUsers = () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("__r_ksLT"));
    axios
      .post(config.APIurl + "getUsers.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => res.data)
      .then(data => {
        if (data.status === "success") {
          this.setState({
            permissions: data.permissions
          });
        }
      })
      .catch(err => console.log(err));
  };
  selectChanged = e => {
    this.setState({ selectSearch: e.target.value });
  };
  check = e => {
    let checkedList = [...this.state.checkedList];
    if (e.target.checked) {
      !checkedList.includes(e.target.name) && checkedList.push(e.target.name);
    } else {
      let index = checkedList.indexOf(e.target.name);
      index > -1 && checkedList.splice(index, 1);
    }
    this.setState({ checkedList });
  };
  uploadFile = () => {
    if (this.state.file !== null) {
      let formData = new FormData();
      formData.append("permissions", JSON.stringify(this.state.checkedList));
      formData.append("avatar", this.state.file);
      formData.append("token", localStorage.getItem("__r_ksLT"));
      this.setState({
        isUploading: true,
        hasUploaded: false,
        noFileError: false
      });
      axios
        .post(config.APIurl + "uploadAndInsert.php", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(res => res.data)
        .then(data => {
          if (data.error === false) {
            let fileURL = data.url;
            console.log(fileURL);
            fileURL = fileURL.split("uploads/");
            fileURL = "https://ryfylkekranservice.no/api/uploads/" + fileURL[1];
            this.setState(
              {
                isUploading: false,
                hasUploaded: true,
                uploadError: false,
                selectSearch: "Vis alle filer",
                search: ""
              },
              () => {
                this.getFiles();
              }
            );
          } else {
            console.log(data);
            this.setState({ isUploading: false, uploadError: true });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ isUploading: false, fileError: true });
        });
    } else {
      this.setState({ noFileError: true });
    }
  };
  getFiles = () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("__r_ksLT"));
    axios
      .post(config.APIurl + "getFiles.php", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => res.data)
      .then(data => {
        if (data.status !== "error") {
          this.setState({ documents: data.documents });
        } else {
          this.setState({ getFileError: true });
        }
      })
      .catch(err => {
        this.setState({ getFileError: true });
      });
  };
  doNothing = () => {
    //
  };
  render() {
    let perms = [];
    let permOptions = [];
    this.state.permissions.forEach((permission, index) => {
      permission.name !== "admin" &&
        perms.push(
          <li key={"permm" + index}>
            <label style={{ cursor: "pointer" }}>
              <input
                type="checkbox"
                id={permission.name}
                name={permission.name}
                onClick={this.check}
              />{" "}
              {permission.name}
            </label>
          </li>
        );
      permission.name !== "admin" &&
        permOptions.push(
          <option key={"permOption" + index}>{permission.name}</option>
        );
    });
    let files = [];
    this.state.documents.forEach((document, index) => {
      let fileName = document.url.split("uploads/");
      fileName = fileName[1];
      if (fileName !== undefined) fileName = fileName.substring(7);
      if (
        (fileName !== undefined &&
          fileName.toUpperCase().search(this.state.realSearch) > -1) ||
        this.state.search === ""
      ) {
        let docPermissions = JSON.parse(document.permissions);
        if (
          this.state.selectSearch === "Vis alle filer" ||
          docPermissions.includes(this.state.selectSearch)
        ) {
          files.push(
            <ListFile
              url={document.url}
              name={fileName}
              fileid={document.id}
              extension={document.extension}
              permissions={this.state.permissions}
              docPermissions={docPermissions}
              update={this.getFiles}
              key={"fileNum" + index}
            />
          );
        }
      }
    });
    return (
      <div className="Upload adminpage">
        <div className="blueBox">
          <h3>Last opp fil</h3>
          {this.state.fileError && (
            <div className="error">Ukjent feil oppstod</div>
          )}
          {this.state.hasUploaded && (
            <div className="success">Fil ble lastet opp</div>
          )}
          {this.state.noFileError && (
            <div className="error">Vennligst velg en fil</div>
          )}
          {this.state.permissions.length > 1 && (
            <React.Fragment>
              <h4>1. Velg rettighetsgrupper for fil</h4>
              <ul>
                <li key="disabledPermmm">
                  <input type="checkbox" disabled={true} checked={true} /> admin
                </li>
                {perms}
              </ul>
              <h4>2. Velg fil</h4>
            </React.Fragment>
          )}
          <div style={{ marginLeft: "15px" }}>
            <input
              type="file"
              onChange={this.fileChanged}
              name="file"
              id="file"
            />
            <label htmlFor="file">
              {this.state.label === "" ? "Velg fil" : this.state.label}
            </label>
            <button
              onClick={
                this.state.isUploading ? this.doNothing : this.uploadFile
              }
            >
              {this.state.isUploading ? "Vent litt..." : "Last opp"}
            </button>
          </div>
          <br />
        </div>
        <div className="yellowBox">
          <h3>Filer</h3>
          <h4>Sorter</h4>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <select
              style={{ width: "100%" }}
              value={this.state.selectSearch}
              onChange={this.selectChanged}
            >
              <option>Vis alle filer</option>
              {permOptions}
            </select>
            <br />
            <input
              className="fileSearch"
              type="text"
              value={this.state.search}
              onChange={this.search}
              placeholder="Søk etter filnavn"
            />
            <h4>Filer</h4>
          </div>
          <div className="fileContainer">{files}</div>
          <br />
        </div>
      </div>
    );
  }
}

export default Upload;
